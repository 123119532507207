* {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  box-sizing: border-box;
}

.header {
  width: 100%;
}

.top-header {
  display: flex;
  align-items: center;
  background: white;
  padding: 10px 20px !important;
  font-size: 14px;
  color: #0f9907;
}

.top-header .icon {
  margin-right: 10px;
  font-size: 20px;
}

.top-header .userAccount {
  color: #0f9907;
}

/* .top-header .btn {
  color: #0f9907;
} */

.top-header .btn:hover {
  text-decoration: underline;
}

.top-header .info p {
  margin: 0;
}

.mid-header {
  display: flex;
  padding: 20px;
  background: #0f9907;
}

/* .mid-header .logo img {
    width: 150px;
} */

.search-box {
  display: flex;
  flex: 1;
  margin: 0 20px;
}

.search-box input {
  flex: 1;
  padding-left: 5px;
  border: 1px solid #ddd;
  border-radius: 5px 0 0 5px;
  /* max-width: 1500px !important; */
}

.search-box button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 0 5px 5px 0;
  background: #0f9907;
  color: #fff;
  cursor: pointer;
}

.search-box button:hover {
  background: #fed700;
  color: #0f9907;
}

.user {
  display: flex;
  align-items: center;
}

.user .icon {
  margin-right: 10px;
  font-size: 20px;
}

.user .btn {
  border: none;
  background: none;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  color: white;
  font-family: sans-serif;
}

.bottom-header {
  display: flex;
  justify-content: space-around;
  padding: 10px;
  background: #0f9907;
  color: #fff;
  z-index: 10;
}

.bottom-header .user-profile {
  display: flex;
  align-items: center;
}

.bottom-header .user-profile .icon {
  margin-right: 10px;
  background: #fff;
  border-radius: 50%;
  padding: 10px;
}

.bottom-header .info {
  display: flex;
  flex-direction: column;
}

.bottom-header .info h2,
.bottom-header .info p {
  margin: 0;
}

.bottom-header .nav ul {
  display: flex;
  list-style: none;
}

.bottom-header .nav ul li {
  margin: 0 10px;
  margin-left: 50px;
  font-weight: bold;
}

.bottom-header .nav .link {
  color: white !important;
  text-decoration: none;
  font-weight: 600;
}

.bottom-header .nav .link:hover {
  color: #fed700;
}

.bottom-header .offer {
  background: #fed700;
  padding: 10px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
}

.shopping_counter {
  background-color: white;
  color: #0f9907;
  font-weight: bold;
  position: relative;
  top: -55px;
  left: 13px;
}

.shopping_counter_bottom {
  background-color: white;
  color: #0f9907;
  font-weight: bold;
  position: relative;
  top: -43px;
  left: 13px;
}

.bottom-nav {
  display: none !important;
  /* display: flex; */
  width: 100%;
  background: #0f9907;
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-around;
  padding: 15px;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
  z-index: 20; /* Ensure the bottom navigation is on top of other elements */
}

.bottom-nav .nav-link {
  color: white;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
}

.bottom-nav .nav-link:hover {
  color: #fed700;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .header .mid-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .search-box {
    width: 100%;
    margin: 10px 0;
  }

  .bottom-header {
    display: none;
  }

  .bottom-nav {
    display: flex !important;
  }
}
