@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'swiper/css';
@import 'swiper/css/pagination';
@import 'swiper/css/navigation';

/* index.css */
html, body {
    height: 100%;
    margin: 0;
    padding: 0;
}

body {
    display: flex; /* Ensure that body behaves like a flex container */
    flex-direction: column; /* For a vertical layout if needed */
}

#root {
    flex: 1; /* This allows #root to take all remaining space */
    display: flex;
    flex-direction: column;
}
