* {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  box-sizing: border-box;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 600px;
  max-height: 80vh;
  overflow-y: auto;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.close-btn {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #666;
}

.product-list {
  margin-bottom: 20px;
}

.product-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin: 10px 0;
  background-color: #f8f9fa;
  border-radius: 4px;
}

.product-info {
  flex-grow: 1;
}

.price-container {
  display: flex;
  gap: 15px;
  margin-top: 5px;
}

.old-price {
  text-decoration: line-through;
  color: #666;
}

.new-price {
  color: #28a745;
  font-weight: bold;
}

.cancel-btn {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 8px 15px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.cancel-btn:hover {
  background-color: #c82333;
}

.order-btn {
  background-color: #0f9907;
  color: white;
  border: none;
  padding: 12px 25px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
  transition: background-color 0.2s;
}

.order-btn:hover {
  background-color: #0f9907;
}

.modal-footer {
  border-top: 1px solid #eee;
  padding-top: 20px;
}

.cart {
  width: 100%;
  padding: 20px;
}

.cart h3 {
  font-size: 24px;
  text-transform: uppercase;
  color: #232323;
  letter-spacing: 1px;
  font-weight: 300;
}

.cart .empty_cart {
  width: 100%;
  text-align: center;
}

/* .cart .empty_cart h2 {
  text-transform: uppercase;
  font-size: 16px;
  color: #232323;
  letter-spacing: 1px;
} */

.error {
  color: red;
  font-weight: bold;
}

.cart .empty_cart button {
  padding: 10px 20px;
  margin-top: 10px;
  /* background: #0f9907; */
  color: white;
  border: none;
  outline: none;
  font-weight: 600;
  cursor: pointer;
}

.cart .container {
  width: 100%;
  padding: 10px;
}

.cart .container .box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* border: 2px solid #f6f6f6; */
  margin-top: 10px;
}

.cart .container .box .img_box img {
  width: 40%;
  height: auto;
}

.cart .container .box .detail {
  /* width: 60%; */
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.cart .container .box .detail .info {
  margin-top: 10px;
}

.cart .container .box .detail .info h4 {
  text-transform: uppercase;
  font-size: 12px;
  /* color: #8a8a8a; */
}

.cart .container .box .detail .info h3 {
  margin-top: 5px;
  /* color: #232323; */
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
}

.cart .container .box .detail p {
  margin-top: 5px;
  /* color: #232323; */
  text-transform: uppercase;
}

.cart .container .box .detail .quantity {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.cart .container .box .detail .quantity button {
  padding: 5px 10px;
  font-size: 14px;
  color: white;
}

.cart .container .box .detail .quantity input {
  height: 30px;
  max-width: 50px;
  font-size: 14px;
}

.cart .container .box .detail .icon {
  position: absolute;
  right: 70px;
}

.cart .container .box .detail .icon:hover {
  color: #0f9907 !important;
  cursor: pointer;
}

.cart .bottom {
  padding: 10px;
}

.cart .bottom .Total h4 {
  font-size: 18px;
  color: #cd176c;
  text-transform: uppercase;
  font-weight: 600;
}

.cart .bottom button {
  padding: 10px 20px;
  /* background: #0f9907; */
  color: white;
  font-weight: 600;
  font-size: 14px;
  margin-top: 10px;
  cursor: pointer;
}

.coupon {
  /* color: #0f9907; */
  text-decoration: underline;
}

.coupon:hover {
  text-decoration: none;
  cursor: pointer;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  width: 50% !important;
}

.modal-content {
  margin: 0 auto;
}

.icon-close {
  position: relative;
  bottom: 30px;
  left: 620px;
}

.icon-close:hover {
  cursor: pointer;
}

.modal-content input {
  padding: 3%;
  width: 100%;
  margin-bottom: 5%;
  /* border: 1px solid #0f9907; */
  border-radius: 5px;
}

.modal-footer {
  display: flex;
  justify-content: end;
}

.modal-footer .coupon-btn {
  /* background-color: #0f9907; */
  padding: 2%;
  color: white;
}


/* Media Queries */
@media (max-width: 768px) {
  .cart h3 {
    font-size: 32px;
  }

  .modal {
    width: 50%;
  }

  .icon-close {
    position: relative;
    bottom: 30px;
    left: 405px;
  }

  .cart .container .box {
    flex-direction: row;
    height: 250px;
  }

  .cart .container .box .img_box img {
    width: 100%;
  }

  .cart .container .box .detail {
    flex-direction: row;
  }

  .cart .container .box .detail .info {
    margin-left: 30px;
  }

  .cart .container .box .detail .quantity {
    margin-top: 65px;
    margin-left: 70px;
  }

  .cart .container .box .detail .quantity button {
    padding: 10px 20px;
    /* background: #0f9907; */
  }

  .cart .container .box .detail .quantity input {
    width: 100px;
  }
}

@media (max-width: 480px) {
  .cart .container {
    width: 100%;
    padding: 10px;
  }

  .modal {
    width: 80% !important;
  }

  .icon-close {
    position: relative;
    bottom: 30px;
    left: 290px;
  }

  .cart .container .box .detail .icon {
    position: absolute;
    right: 40px;
  }

  .cart .container .box .img_box img {
    width: 100%;
    height: auto;
  }

  .cart .container .box .detail .icon:hover {
    color: #0f9907 !important;
    cursor: pointer;
  }

  .cart .container .box .detail {
    display: flex;
    flex-direction: column;
    padding: 10px;
  }

  .cart .container .box .detail .quantity {
    margin-top: 20px !important;
    margin-left: 30px;
    display: flex;
    align-items: center;
  }
}
