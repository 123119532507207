.error {
  color: red;
  margin-top: 5px;
  font-size: 12px;
  font-weight: bold;
}

#root > div.container > div > div.second > div.mb-5.text-center > button:hover {
  color: "#0f9907" !important;
  /* background: white !important; */
  text-decoration: underline;
}

.container {
  margin: 0 !important;
}

/* @media (min-width:1024px) {
  .container {
      max-width: 1350px !important;
  }
} */