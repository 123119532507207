*
{
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    box-sizing: border-box;
}
.contact
{
    padding: 20px 40px;
    width: 100%;
}
.contact .container
{
    padding: 10px 20px;
    max-width: 100%;
}
.contact .container .form
{
    width: 100%;
    background: #f6f6f6;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin: 0px auto;
    border-top: 3px solid #0f9907;
    border-radius: 5px;
}
.contact .container .form h2
{
    color: #cd1e7c;
    text-transform: uppercase;
    font-size: 18px;
    letter-spacing: 1px;
    font-weight: 600;
}

.contact .container .form .box .lable
{
    padding: 8px;
}

.contact .container .form button
{
    padding: 10px 20px;
    color: white;
    border: none;
    outline: none;
    background: none;
    background: #0f9907;
    cursor: pointer;
}

.contact .container .form .heading
{
    font-size: 25px;
}

.contact .container .form .button
{
    margin-top: 20px;
    display: flex;
    justify-content: end;
}