* {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  box-sizing: border-box;
}

.footer {
  background: #f6f6f6;
  padding: 20px 5%;
}

.footer-top {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.service {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.service-item {
  flex: 1 1 30%;
  display: flex;
  align-items: center;
  margin: 10px 0;
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
}

.service-item svg {
  font-size: 24px;
  /* color: #0f9907; */
  margin-right: 10px;
}

.service-item h3 {
  font-size: 16px;
  /* color: #0f9907; */
  font-weight: 600;
}

.footer-bottom {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.footer-logo {
  flex: 1 1 10%;
  text-align: center;
}

.footer-logo img {
  width: 150px;
  margin-bottom: 10px;
}

.footer-logo p {
  font-size: 14px;
  color: #8a8a8a;
}

.footer-links {
  flex: 1 1 55%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 25px;
}

.links-group {
  flex: 1 1 30%;
  padding: 10px;
}

.links-group h3 {
  font-size: 14px;
  /* color: #232323; */
  margin-bottom: 10px;
  text-transform: uppercase;
}

.links-group ul {
  list-style: none;
}

.links-group ul li {
  font-size: 12px;
  color: #8a8a8a;
  margin-bottom: 5px;
  cursor: pointer;
  transition: color 0.3s;
}

/* .links-group ul li:hover {
  color: #0f9907;
} */

.app{
  width: 50%;
}

@media (max-width: 768px) {
  .footer {
    padding: 20px 1%;
  }

  .app{
    width: 40%;
  }

  .footer-top,
  .footer-bottom {
    flex-direction: column;
    align-items: center;
  }

  .service-item {
    flex: 1 1 100%;
    margin: 10px 0;
  }

  .footer-logo img {
    width: 120px;
  }

  .footer-links {
    flex-direction: column;
    align-items: center;
  }

  .links-group {
    flex: 1 1 100%;
    text-align: center;
  }
}

@media (max-width: 480px) {
  .service-item h3 {
    font-size: 11px;
    color: #0f9907;
    font-weight: 600;
  }

  .footer-logo {
    display: flex;
  }

  .footer-logo p{
    display: flex;
    align-items: center;
  }
  .app{
    width: 40%;
  }
}
