.order-page {
    padding: 20px;
    font-family: Arial, sans-serif;
}

.tabs {
    display: flex;
    margin-bottom: 20px;
}

.tab-button {
    background-color: #f0f0f0;
    /* border: 1px solid #ccc; */
    border-radius: 5px;
    padding: 10px 20px;
    margin-right: 10px;
    cursor: pointer;
}

/* .tab-button.active {
    background-color: #0f9907;
    color: white;
    border-color: #0f9907;
} */

.tab-content {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
}

.order-item {
    margin-bottom: 20px;
}

.order-details {
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 20px;
    background-color: #fff;
}

.order-item .order-image {
    max-width: 100px;
    margin-right: 20px;
}

.item-details {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.item-info {
    flex: 1;
}

.item-info h4 {
    margin: 0;
}

.item-info p {
    margin: 5px 0;
}

button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 10px;
}

/* button:hover {
    background-color: #0f9907;
    color: white;
} */

.order-item button {
    background-color: #dc3545;
    padding: 10px;
    color: white;
    margin-top: 10px;
}

.order-item button:hover {
    background-color: #c82333;
}
