.categories-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 16px;
  padding: 16px;
}

.category-card {
  text-align: center;
  /* border: 1px solid #ddd; */
  border-radius: 8px;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  margin: 0 auto;
  transition: transform 0.3s, box-shadow 0.3s;
  cursor: pointer;
  max-width: 100px !important;
}

.category-card:hover {
  transform: scale(1.05);
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
}

.category-card.active {
  border-color: #007bff;
  background-color: #f0f8ff;
}

.category-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.category-name {
  margin-top: 8px;
  font-size: 16px;
  font-weight: bold;
  padding: 10px;
}

/* Mobile-friendly styles */
@media (max-width: 768px) {
  /* Apply these styles only when categories are present */
  .categories-container.has-categories {
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;
  }

  .category-card {
    margin-bottom: 10px;
    padding: 0px !important;
  }

  .category-name {
    font-size: 12px;
  }

  /* Optional: Style for when no categories are present */
  .categories-container.no-categories {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}
