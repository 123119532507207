/* Global Styles */
* {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  box-sizing: border-box;
}

.shopx {
  width: 100%;
  padding: 20px 30px;
}

.shopx h2 {
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #232323;
  font-size: 32px;
  font-weight: 300;
}

.shopx p {
  margin-top: 5px;
  color: #8a8a8a;
  font-size: 14px;
  font-weight: 600;
}

.shopx .container {
  max-width: 100%;
  display: flex;
  margin-top: 20px;
  justify-content: center;
}

.shopx .container .right_box {
  width: 74%;
}

.shopx .container .right_box .banner,
.banner_top {
  max-width: 100%;
}

.shopx .container .right_box .banner .img_box img,
.banner_top .img_box img {
  width: 100%;
  object-fit: cover;
}

.shopx .container .right_box .product_box {
  max-width: 100%;
  padding: 10px 10px;
}

.shopx .container .right_box .product_box h2 {
  /* color: #232323; */
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 22px;
  margin-top: 10px;
}

.shopx .container .right_box .product_box .product_container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.shopx .container .right_box .product_box .product_container .box {
  padding: 10px 10px;
  /* border: 3px solid #f6f6f6; */
  margin-left: 15px;
  margin-top: 20px;
  flex: 1 1 calc(33.33% - 30px);
  /* background-color: #fff; */
  transition: transform 0.5s;
  position: relative;
}

.shopx .container .right_box .product_box .product_container .box:hover {
  transform: scale(1.05);
}

.shopx .container .right_box .product_box .product_container .box .img_box {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.shopx .container .right_box .product_box .product_container .box .img_box img {
  width: 100%;
  height: auto;
}

.shopx
  .container
  .right_box
  .product_box
  .product_container
  .box
  .img_box
  .icon {
  display: flex;
  flex-direction: column;
  padding: 10px 10px;
  margin-top: 10px;
  transition: 0.5s;
  align-items: center;
  position: absolute;
  right: 10px;
  top: 10px;
  opacity: 0;
}

.shopx
  .container
  .right_box
  .product_box
  .product_container
  .box:hover
  .img_box
  .icon {
  opacity: 1;
}

.shopx
  .container
  .right_box
  .product_box
  .product_container
  .box
  .img_box
  .icon
  li {
  list-style: none;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 10px 10px;
  margin-top: 10px;
  color: #8a8a8a;
  /* background: #fff; */
  cursor: pointer;
  transition: 0.5s;
  border-radius: 5px;
}

/* .shopx
  .container
  .right_box
  .product_box
  .product_container
  .box
  .img_box
  .icon
  li:hover {
  color: #fff;
  background: #0f9907;
  border-radius: 5px;
} */

#root > div.shopx > div > div > div > div.product_container > div > div > div.img_box > div > li.remove:hover {
  color: #fff;
  background: red;
  border-radius: 5px;
}

#root > div.shopx > div > div > div > div.product_container > div > div > div.img_box > div > li.remove {
  color: #8a8a8a;
  background: #fff;
  border-radius: 5px;
}

.shopx .container .right_box .product_box .product_container .box .detail {
  margin-top: 10px;
  width: 100%;
  text-align: center;
}

.shopx .container .right_box .product_box .product_container .box .detail h3 {
  /* color: #232323; */
  font-size: 18px;
  font-weight: 600;
  text-transform: capitalize;
}

.shopx .container .right_box .product_box .product_container .box .detail p {
  margin-top: 5px;
  /* color: #0f9907; */
  font-size: 16px;
}

.shopx
  .container
  .right_box
  .product_box
  .product_container
  .box
  .detail
  .min_order {
  margin-top: 5px;
  /* color: gray; */
  font-size: 16px;
}

.shopx
  .container
  .right_box
  .product_box
  .product_container
  .box
  .detail
  .stock {
  margin-top: 5px;
  /* color: gray; */
  font-size: 16px;
}

.shopx
  .container
  .right_box
  .product_box
  .product_container
  .box
  .detail
  button {
  margin-top: 10px;
  padding: 10px 20px;
  border: none;
  outline: none;
  /* color: white;
  background: #0f9907; */
  font-weight: 600;
  cursor: pointer;
  transition: 0.5s;
  display: inline-block;
}
span {
  margin-left: 10px;
  margin-right: 10px;
}

/* .shopx
  .container
  .right_box
  .product_box
  .product_container
  .box
  .detail
  button:hover {
  border: 1px solid #0f9907;
  border-color: #0f9907;
  color: #0f9907;
  background: white;
} */

.product_detailx {
  width: 900px;
  height: 500px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border: 1px solid #f6f6f6;
  border-radius: 5px;
  /* padding: 20px 30px; */
  z-index: 1000;
}

.product_detailx .close_btn {
  float: right;
  border: none;
  outline: none;
  background: none;
  /* color: #0f9907; */
  font-size: 22px;
  cursor: pointer;
}

.product_detailx .container {
  width: 100%;
  display: flex;
}

.product_detailx .container .img_box {
  padding: 20px 20px;
  /* background: #e6f5e5; */
  border-radius: 10px;
}

.product_detailx .container .img_box img {
  width: 270px;
  height: 270px;
  object-fit: cover;
}

.product_detailx .container .info {
  margin-top: 30px;
  margin-left: 40px;
}

.product_detailx .container .info h4 {
  color: #8a8a8a;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
}

.product_detailx .container .info h2 {
  margin-top: 30px;
  color: #232323;
  text-transform: uppercase;
  font-size: 22px;
  font-weight: 600;
}

.product_detailx .container .info p {
  margin-top: 10px;
  color: #8a8a8a;
  font-size: 14px;
  letter-spacing: 1px;
  font-weight: 600;
}

.product_detailx .container .info h3 {
  margin-top: 10px;
  font-size: 24px;
  color: #0f9907;
}

.product_detailx .container .info button {
  margin-top: 10px;
  padding: 10px 30px;
  border: none;
  outline: none;
  background: none;
  background: #0f9907;
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
  cursor: pointer;
  transition: 0.5s;
}

.product_detailx .container .info button:hover {
  background: #232323;
}

.has-filter {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .shopx .container {
    flex-direction: column;
  }

  .shopx .container .left_box,
  .shopx .container .right_box {
    width: 100%;
  }
  .shopx .container .left_box .banner {
    display: none;
  }

  .shopx .container .left_box .category .box {
    width: 100%;
    padding: 0px 0px;
  }

  .shopx .container .right_box .product_box .product_container .box {
    flex: 1 1 calc(50% - 10px);
  }
}

@media (max-width: 480px) {
  .shopx .container .left_box .category .box ul li {
    flex: 1 1 calc(50% - 10px);
  }

  .shopx .container .right_box .product_box {
    max-width: 100%;
    padding: 30px 10px;
  }
}
/* Responsive Styles for Mobile Devices */
@media (max-width: 768px) {
  .product_detailx {
    width: 90%;
    max-width: 600px; /* Adjusted max-width */
    height: auto; /* Auto height to fit content */
    top: 5%; /* Slightly adjust top position */
    left: 50%;
    transform: translateX(-50%); /* Center horizontally */
    padding: 15px;
  }

  .product_detailx .container {
    flex-direction: column;
  }

  .product_detailx .container .img_box {
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: center; /* Center the image horizontally */
  }

  .product_detailx .container .img_box img {
    width: 100%;
    max-width: 300px; /* Restrict maximum width */
    height: auto; /* Maintain aspect ratio */
    object-fit: cover; /* Maintain image aspect ratio */
  }

  .product_detailx .container .info {
    margin-top: 20px;
    text-align: center; /* Center text in the info section */
  }

  .product_detailx .container .info h2 {
    font-size: 18px;
  }

  .product_detailx .container .info p {
    font-size: 14px;
  }

  .product_detailx .container .info h3 {
    font-size: 20px;
  }

  .product_detailx .container .info button {
    padding: 10px;
    font-size: 14px;
  }

  .product_detailx .close_btn {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 22px;
    color: #0f9907;
  }
}

@media (max-width: 480px) {
  .shopx {
    padding: 0;
  }
  .shopx .container {
    max-width: 100%;
    display: flex;
    margin-top: 0px;
  }
  .banner_top {
    display: flex;
    max-height: 100vh;
  }

  .banner {
    display: none;
  }

  .shopx .container .left_box .category .header {
    display: none;
  }

  .shopx .container .left_box .category {
    border: none;
  }

  .product_detailx {
    width: 90%; /* Make the width a percentage to adapt to different screen sizes */
    height: auto; /* Let the height adjust automatically based on content */
    top: 10px; /* Position the popup near the top of the screen */
    left: 50%;
    transform: translateX(-50%); /* Only center horizontally */
    padding: 15px 20px; /* Adjust padding for smaller screens */
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 5px; /* Slightly lighter shadow */
  }

  .product_detailx .container {
    flex-direction: column;
    margin-top: 11px;
  }

  .product_detailx .container .img_box {
    width: 100%;
    padding: 5px;
    display: flex;
    justify-content: center; /* Center the image horizontally */
  }

  .product_detailx .container .img_box img {
    width: 100%;
    max-width: 250px; /* Restrict maximum width */
    height: auto; /* Maintain aspect ratio */
    object-fit: cover; /* Maintain image aspect ratio */
  }

  .product_detailx .container .info {
    margin-top: 10px;
    text-align: center; /* Center text in the info section */
  }

  .product_detailx .container .info h2 {
    font-size: 16px;
  }

  .product_detailx .container .info p {
    font-size: 12px;
  }

  .product_detailx .container .info h3 {
    font-size: 18px;
  }

  .product_detailx .container .info button {
    padding: 8px;
    font-size: 12px;
  }

  .product_detailx .close_btn {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 18px;
    color: #0f9907;
  }
}
